const inputStyles = {
  base: {
    color: '#434343',
    fontFamily: '"Graphik-Regular", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#434343',
      fontFamily: '"Graphik-Regular", Helvetica, sans-serif',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
  },
};

let stripe;
function createStripeInstance() {
  if (!stripe) {
    stripe = global.Stripe(process.env.VUE_APP_STRIPE_KEY);
  }

  return stripe;
}

function handleCardInputChange(event) {
  const displayError = document.getElementById('card-errors');
  if (event.error) {
    displayError.textContent = event.error.message;
  } else {
    displayError.textContent = '';
  }

  if (event.complete && !event.error) {
    this.emitComplete();
  }
}

export {
  handleCardInputChange,
  inputStyles,
  createStripeInstance,
};
